import React from 'react'
import Helmet from 'react-helmet'
import FAQCustomersection from '../../PageComponents/FAQsection'

export default function FaqSecondary({ faqs }) {
  return (
    <section className="faq-section container">
      {faqs && (
        <Helmet>
          <script type="application/ld+json">{`
                        {
                            "@context": "http://schema.org",
                            "@type": "FAQPage",
                            "mainEntity": [
                                ${
                                  faqs &&
                                  faqs.map((faq, index) => {
                                    return `
                                            {
                                                "@type": "Question",
                                                "name": "${faq.question}",
                                                "acceptedAnswer": {
                                                    "@type": "Answer",
                                                    "text": "${faq.Answer}"
                                                }
                                            }
                                        `
                                  })
                                }
                            ]
                        }
                    `}</script>
        </Helmet>
      )}

      {/* / FAQ Section / */}
      <FAQCustomersection
        primaryHeading="FAQS?"
        secondaryHeading="Have questions? Look here"
        description=""
        faqList={faqs}
      />
    </section>
  )
}
